// extracted by mini-css-extract-plugin
export var backgroundAnimation = "quote-module--backgroundAnimation--197a3";
export var button = "quote-module--button--887df";
export var container = "quote-module--container--c52ce";
export var contentWrapper = "quote-module--content-wrapper--8ad83";
export var description = "quote-module--description--53e7a";
export var grid = "quote-module--grid--b567f";
export var image = "quote-module--image--8b6b5";
export var ratioClass = "quote-module--ratio-class--55422";
export var subtitle = "quote-module--subtitle--c280c";
export var title = "quote-module--title--13c4e";