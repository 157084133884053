import React from 'react';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    container,
    grid,
    contentWrapper,
    title as titleClass,
    subtitle as subtitleClass,
    description as descriptionClass,
    button as buttonClass,
    image,
    ratioClass,
} from './quote.module.scss';

import { ISection, ISectionContentButton } from '../../models/section.model';

import Section from '../hoc/section';
import Title from '../atoms/title';
import Subtitle from '../atoms/subtitle';
import Content from '../atoms/content';
import Markdown from '../hoc/markdown';
import Button from '../atoms/button';

export interface IQuoteProps {
    className?: string;
    section: ISection;
    TitleTag?: React.ElementType;
}

export default function Quote({ TitleTag, section, className = '' }: IQuoteProps) {
    const { content, style, sectionId, css } = section;
    const { texts, buttons, media } = content;

    const [title, subtitle, description] = texts;
    const { text: buttonText, url: buttonUrl }: Partial<ISectionContentButton> = buttons[0] ?? {};

    return (
        <Section
            className={`${className} ${container}`}
            classes={{
                container: grid,
            }}
            TitleTag={TitleTag}
            style={style}
            sectionId={sectionId}
            css={css}
        >
            <div className={contentWrapper}>
                {subtitle && <Subtitle className={subtitleClass}>{subtitle}</Subtitle>}
                {title && (
                    <Title className={titleClass} Tag={TitleTag}>
                        {title}
                    </Title>
                )}
                {description && (
                    <Content className={descriptionClass}>
                        <Markdown>{description}</Markdown>
                    </Content>
                )}
                {buttonText && buttonUrl && (
                    <Button as="link" className={buttonClass} to={buttonUrl}>
                        {buttonText}
                    </Button>
                )}
            </div>
            <Image media={media} className={image} ratioClass={ratioClass} objectFit={'contain'} />
        </Section>
    );
}
